.react-ruler-wrapper .ruler-container {
  margin-top: 60px;
}
.react-ruler-wrapper .ruler-wrapper {
  position: relative;
}
.react-ruler-wrapper .ruler-list {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #dee3e6;
}
.react-ruler-wrapper .ruler-list .line {
  height: 6px;
  border-left: 1px solid #dee3e6;
}
.react-ruler-wrapper .ruler-list .rule-mark .line-text {
  position: absolute;
  top: -22px;
  margin-left: -4px;
  font-size: 10px;
  color: #a3afb7;
}
.react-ruler-wrapper .ruler-list .rule-mark .line {
  border-left: 1px solid #dee3e6;
  height: 20px;
}
.react-ruler-wrapper .ruler-underline {
  width: 296px;
  height: 1px;
  background-color: #dee3e6;
}
.react-ruler-wrapper .ruler-drag {
  position: absolute;
  left: 0;
  bottom: 46px;
  height: 100%;
  z-index: 2;
  -webkit-transform-origin: left;
          transform-origin: left;
}
.react-ruler-wrapper .ruler-drag .ruler-point {
  position: absolute;
  right: 0;
  cursor: pointer;
}
.react-ruler-wrapper .ruler-drag .ruler-point .point {
  position: absolute;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 600;
  background-color: #763689;
  color: #FFFFFF;
  margin-bottom: 5px;
  line-height: 24px;
  height: 30px;
  min-width: 48px;
  border-radius: 4px;
  top: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.react-ruler-wrapper .ruler-drag .ruler-point .point:after {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #763689 transparent transparent transparent;
  line-height: 0px;
  left: 0;
  right: 0;
  margin: auto;
}
.react-ruler-wrapper .ruler-drag .ruler-point .ruler-line {
  width: 2px;
  height: 38px;
  background-color: #763689;
  cursor: pointer;
  margin-top: 29px;
}
* {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}
